import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

import ProductHero from './bifold-door.png'

import WeatherSeals from './feature-dual-weather-seals.png'
// import EgressHinges from './feature-egress-hinges.png'
// import CentorHardware from './feature-centor-hardware.png'
import ToughenedGlass from './feature-24mm-glazing.png'

const Bifold = () => (
  <Layout>
    <Helmet title="Bifold Doors | A style statement creating socialable spaces | Rudd Joinery">
      <meta name="description" content="Our bespoke Bifold doorsets can help you make a style statement and create sociable spaces. The door stacks neatly and compactly to the side, creating an open-plan area as well as the option to divide spaces, such as between a house and a conservatory during the evenings" />      
    </Helmet>
    <Hero heroImage="bifold-hero" heroId="hero" nextId="description">
      <h1>Bifold</h1>
      <h2>A style statement creating socialable spaces</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Our bespoke <strong>Bifold</strong> doorsets from our{' '}
            <strong>Performance Bifold range</strong> can help you make a style
            statement and create sociable spaces. The door stacks neatly and
            compactly to the side, creating an open-plan area as well as the
            option to divide spaces, such as between a house and a conservatory
            during the evenings. Bifold doors can help you get the most from
            your home and use it in a way that suits you and your family, so
            ours come with all the features and benefits of contemporary doors,
            from performance weatherseals to security features like the
            multi-point locking system that is fitted as standard.
          </p>
          <p>
            Choose from a range of configurations, from two doors up to eight,
            various glazing options and from almost 150 different Teknos paint
            colours. Easy to maintain, long lasting and with the potential to
            change the way you use your home.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Entrance Door" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky ovalroomblue">
        <div className="expanded">
          <h3>Our Bifold door features</h3>
          <ul>
            <li>High performance weather seals</li>
            {/* <li>Multi-point espag Locking</li> */}
            {/* <li>Centor Bifold hardware</li> */}
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__5">
          <h2>High performance weather seals</h2>
          <p>
            Featuring high performance weather seals to prevent weather ingress in
            exposed locations.
          </p>
        </div>
        <img src={WeatherSeals} alt="Dual weather seals" className="c__2"  />
      </div>
      {/* <div className="feature-bar lightgrey">
        <div className="block c__1" />
      </div> */}
      {/* <div className="feature-bar">
        <div className="block c__1">
          <h2>Centor bifold hardware</h2>
          <p>

          </p>
        </div>
      </div> */}
      <div className="feature-bar lightgrey">
        <div className="block c__1">
          <h2>24mm toughened double glazing</h2>
          <p>
            High energy efficiency is further increased by argon filled double
            glazing.
          </p>
        </div>
        <img src={ToughenedGlass} alt="24mm toughened double glazing" className="c__5" />
      </div>
      <div className="cta-bar ovalroomblue">
        <div className="expanded">
          <p>
            Our <strong>Performance Bifold</strong> door range can be installed
            alongside our windows and doors, as all our mouldings are
            complimented across our product range
          </p>          
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="bifold-hero" />
    <div className="section ovalroomblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Bifold door options</h2>
        </div>
        <ProductOptions ProductName="Bifold door" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Bifold door</h2>
          <p>
            Our bespoke <strong>Bifold</strong> door can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Bifold